<template>
  <div>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.name')"
            v-model="form.name"
            :placeholder="$t('placeholder.enter_name')"
            required
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-datepicker
            :label="$t('label.inspection_date')"
            :placeholder="$t('placeholder.choose_inspection_date')"
            :value="form.inspection_date"
            required
            @change="form.inspection_date = $event"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-datepicker
            :label="$t('label.expired_at')"
            :placeholder="$t('placeholder.choose_expired_at_date')"
            :value="form.expired_date"
            :min-date="new Date()"
            required
            @change="form.expired_date = $event"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppDatepicker from '@/components/form-groups/AppDatepicker';
import AppInput from '@/components/form-groups/AppInput';

export default {
  components: {
    AppDatepicker,
    AppInput
  },
  props: {
    certificate: {type: Object, default: () => {}}
  },
  data() {
    return {
      updatePageFlag: false,
      form: {
        elevator_id: null,
        inspection_date: null,
        expired_date: null,
        name: null
      },
      error: {
        inspection_date: null,
        expired_date: false,
        name: false
      }
    }
  },
  beforeMount() {
    this.form.elevator_id = this.$route.query.elevator_id;
  },
  watch: {
    certificate(data) {
      if (data) {
        this.updatePageFlag = true;

        this.form.elevator_id = data.elevator.id;
        this.form.inspection_date = data.inspection_date;
        this.form.expired_date = data.expired_date;
        this.form.name = data.name;
      }
    },
    form: {
      handler(data) {
        this.$emit('change', {form: data, validation: this.validateForm()});
      },
      deep: true
    }
  },
  methods: {
    validateForm() {
      return !Object.values(this.form).some(x => !x);
    }
  }
}
</script>