<template>
  <div>
    <app-card>
      <template #body>
        <certificate-form :block-id="blockId" :certificate="certificate" @change="onFormChange"/>
      </template>
    </app-card>

    <portal to="subheader-toolbar">
      <b-button class="mr-3" variant="transparent-white" @click="$router.push({name: 'showBlock', params: {id: blockId}, hash: '#elevators'})">
        {{ $t('btn.back_to_block') }}
      </b-button>
      <b-button class="mr-3" variant="danger" v-b-modal.delete-certificate-modal>
        {{ $t('btn.delete') }}
      </b-button>
      <b-button variant="success" :disabled="!validation" @click="updateItem">
        {{ $t('btn.update') }}
      </b-button>
    </portal>

    <app-modal
        id="delete-certificate-modal"
        :title="$t('label.confirm_action')"
        :ok-text="$t('btn.delete')"
        ok-variant="danger"
        @ok="deleteItem"
    >
      <template #app-modal-body>
        <span class="font-size-lg">{{ $t('modal.delete_certificate') }}?</span>
      </template>
    </app-modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppCard from '@/components/AppCard';
import AppModal from '@/components/modals/AppModal';
import CertificateForm from './components/CertificateUpdateForm';

export default {
  components: {
    AppCard,
    AppModal,
    CertificateForm
  },
  data: () => ({
    blockId: null,
    form: {},
    validation: false
  }),
  beforeMount() {
    this.$store.dispatch('elevatorCertificatesStore/GET_CERTIFICATE', this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      certificate: 'elevatorCertificatesStore/CERTIFICATE'
    })
  },
  watch: {
    certificate(data) {
      this.blockId = data.block.id;
    }
  },
  methods: {
    onFormChange(e) {
      this.form = e.form;
      this.validation = e.validation;
    },
    updateItem() {
      if (!this.validation) return false;
      this.$store.dispatch('elevatorCertificatesStore/UPDATE', {id: this.certificate.id, data: this.form}).then(() => {
        this.$router.push({name: 'showBlock', params: {id: this.blockId}, hash: '#elevators'});
      });
    },
    deleteItem() {
      this.$store.dispatch('elevatorCertificatesStore/DELETE', this.certificate.id).then(() => {
        this.$router.push({name: 'showBlock', params: {id: this.blockId}, hash: '#elevators'});
      });
    }
  }
}
</script>